import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../general/Table';
import Tile from '../../general/Tile';
import Link from '../../navigation/Link';
import TileAttribute from '../../general/TileAttribute';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import PriceWhole from '../../project/PriceWhole';
import ProductMoves from '../../project/ProductMoves';
import InputQuantity from '../../project/InputQuantity';
import TaxedPriceDisplay from '../../project/TaxedPriceDisplay';
import ProductEditableWithMovesRQO from '../../project/ProductEditableWithMovesRQO';
import CustomerCodeEditableRQO from '../../project/CustomerCodeEditableRQO';
import InputUnitPrice from '../../project/InputUnitPrice';
import InputTextArea from '../../general/InputTextArea';
import withDataHOC from '../../dataProvider/withDataHOC';
import locationHOC from '../../locationProvider/locationHOC';
import RemoveOrderItem from './RemoveOrderItem';
import OrderItemQuantityForDealer from './OrderItemQuantityForDealer';
import {QUERY_PARAMS, ROUTES} from '../../../constants/navigation';
import {formatPrice, formatPercentage, formatQuantity, formatUnitPrice, formatQuantityPerPackage, formatString} from '../../../lib/formatting';
import {numberOrNull} from '../../../lib/number';
import {Trans, t} from '@lingui/macro';
import {navigateToParametrized, getQueryParam, getQueryParamNumber} from '../../../lib/url';
import {isOrderSend, isTradeSell, getBackgroundStyle, getDefaultTablePageSize, isOrderRemote, getInputOnChangeEventChecked, isOrderDivisible} from '../../../lib/project';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {RIGHTS} from '../../../constants/Rights';
import {OrderDetail, OrderDetailLine, Customer, UserAddress, TransportType} from '../../../constants/propTypesDefinitions';
import withSessionHOC from '../../sessionProvider/withSessionHOC';
import PurchasePricesModal from '../../project/productTable/PurchasePricesModal';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import InputNumber from '../../general/InputNumber';
import OrderAvailableAt from './OrderAvailableAt';
import OrderRequestedAt from './OrderRequestedAt';
import orderItemHOC from './orderItemHOC';
import OrderLineStatesSelect from '../../project/OrderLineStatesSelect';
import OrderAddProduct from './OrderAddProduct';
import OrderLinePriceLevel from './OrderLinePriceLevel';
import StockOptionsSelect from '../../project/StockOptionsSelect';
import { Button, Checkbox, notification } from 'antd';
import { stopPropagation } from '../../../lib/interaction';
import InputText from '../../general/InputText';
import UnitPrice from '../../general/UnitPrice';
import { createFetchOrderEdit } from '../../../backend/apiCalls';
import { merge } from '../../../lib/object';
import DeliveryAddressModal from './DeliveryAddressModal';
import Address from '../../general/Address';
import SpecialTransportIcon from '../../project/SpecialTransportIcon';

/**
 * @fero
 */

class OrderDetailsTable extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object.isRequired,
        orderDetails: OrderDetail.isRequired,
        editOrder: PropTypes.func.isRequired,
        customerAddresses: PropTypes.arrayOf(UserAddress.isRequired).isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showSearch: false,
        };
    }

    setPhrase = (newPhrase) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ORDER_DETAIL_PHRASE]: newPhrase,
        });
    };

    setOrdering = (newOrder) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ORDER_DETAIL_ORDER_BY]: newOrder,
        });
    };

    setLimit = (newLimit) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ORDER_DETAIL_LIMIT]: newLimit,
        });
    };

    setOffset = (newOffset) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ORDER_DETAIL_OFFSET]: newOffset,
        });
    };

    setLineStatus = (newStatusId) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ORDER_DETAIL_OFFSET]: null,
            [QUERY_PARAMS.ORDER_DETAIL_STATUS]: newStatusId,
        });
    };

    editOrder = (queryParams) => {
        const {orderDetails} = this.props;
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        fetchHandler(createFetchOrderEdit(),
            merge({id: orderDetails.id}, queryParams),
            null,
            () => reload([GLOBAL_DATA.ORDER_DETAILS]),
            (error) => {
                notification['error']({ 
                    message: error.message,
                    duration: 10,
                });
            }
        );
    };

    onAtomicChange = (ev) => {
        const val = getInputOnChangeEventChecked(ev);
        this.editOrder({is_atomic: val ? 1 : 0});
    };

    render() {
        const {orderDetails = {}, customerAddresses, location, 
            [SESSION_ATTRIBUTES.SETTINGS]: settings
        } = this.props;
        const {showSearch} = this.state;
        const tableData = orderDetails.lines != null ? orderDetails.lines : [];
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        const orderBy = getQueryParam(location, QUERY_PARAMS.ORDER_DETAIL_ORDER_BY);
        const limit = getQueryParamNumber(location, QUERY_PARAMS.ORDER_DETAIL_LIMIT);
        const offset = getQueryParamNumber(location, QUERY_PARAMS.ORDER_DETAIL_OFFSET);
        const statusId = getQueryParam(location, QUERY_PARAMS.ORDER_DETAIL_STATUS);
        const phrase = getQueryParam(location, QUERY_PARAMS.ORDER_DETAIL_PHRASE);

        const isSent = isOrderSend(orderDetails);
        const isSelling = isTradeSell(orderDetails.trade);
        const isRemote = isOrderRemote(orderDetails);
        const isProxy = orderDetails.final_order != null;
        const isDivisible = isOrderDivisible(orderDetails); 
        const doSearch = (phrase != null && phrase != "") || showSearch;
        const useVAT = settings.use_vat;

        return <div>
            <Table
                BodyRow={OrderDetailsTableRow}
                BodyTile={OrderDetailsTile}
                data={tableData}
                isSelling={isSelling}
                isSent={isSent}
                useVAT={useVAT}
                isRemote={isRemote}
                isDivisible={isDivisible}
                orderBy={orderBy}
                changeOrder={this.setOrdering}
                limit={limit}
                changeLimit={this.setLimit}
                offset={offset}
                changeOffset={this.setOffset}
                pageSize={getDefaultTablePageSize()}
                totalCount={orderDetails.lines_count}
                finalOrder={orderDetails}
                transportType={orderDetails.transport_type}
                customerAddresses={customerAddresses}
                reload={() => {
                    reload([GLOBAL_DATA.ORDER_DETAILS])
                }}
                footer={{
                    node: <PriceWhole linesCount={tableData.length} prices={orderDetails} currency={orderDetails.id_currency}/>,
                    heightRem: 7.5
                }}
                notScrollable={true}
                colDefs={[
                    {
                        headerCell: <Trans>Č.</Trans>,
                        orderCol: 'sequence',
                        class: 'order-details-table-col-sequence'
                    },
                    {
                        headerCell: doSearch ? 
                            <InputText
                                className="full-size-width"
                                size="small"
                                value={phrase}
                                onChange={this.setPhrase}
                                allowClear={true}
                                placeholder="hľadať ..."
                            />
                            :
                            <div className="d-flex align-items-center">
                                <Trans>Názov produktu a výrobca</Trans>
                                <Button 
                                    size="small"
                                    icon="search" 
                                    className="ml-2"
                                    onClick={(ev) => {
                                        stopPropagation(ev);
                                        this.setState({showSearch: true});
                                    }}
                                />
                            </div>,
                        orderCol: doSearch ? null : 'designation',
                        class: 'order-details-table-col-designation'
                    },
                    {
                        headerCell: <Trans>Vlastné označenie</Trans>,
                        orderCol: 'customer_code',
                        class: 'order-details-table-col-customer-code',
                    },
                    {
                        headerCell: <Trans>Množstvo</Trans>,
                        orderCol: 'package_quantity',
                        class: 'order-details-table-col-quantity'
                    },
                    {
                        headerCell: <Trans>Cena za jednotku</Trans>,
                        orderCol: 'package_price',
                        class: 'order-details-table-col-unit-price'
                    },
                    {
                        headerCell: <Trans>Cena spolu</Trans>,
                        orderCol: 'line_price',
                        class: 'order-details-table-col-line-price'
                    },
                    useVAT ? {
                        headerCell: <Trans>Cena s DPH</Trans>,
                        orderCol: 'total_price',
                        class: 'order-details-table-col-total-price'
                    } : null,
                    {
                        headerCell: 
                            <div>
                                <Trans>Dátum potreby</Trans>
                                <Checkbox 
                                    onChange={this.onAtomicChange} 
                                    disabled={!isDivisible}
                                    onClick={(ev) => { stopPropagation(ev); }}
                                >
                                    <Trans>iba v celku</Trans>
                                </Checkbox>
                            </div>,
                        orderCol: 'requested_at',
                        class: 'order-details-table-col-requested-at',
                        rightsFrom: RIGHTS.MARKETING,
                    },
                    {
                        headerCell: <Trans>Dodací termín</Trans>,
                        orderCol: 'available_at',
                        class: 'order-details-table-col-available-at'
                    },
                    {
                        headerCell: <Trans>Poznámka</Trans>,
                        orderCol: 'customer_notice',
                        class: 'order-details-table-col-notice'
                    },
                    {
                        headerCell: <div className="position-static">
                            <div className="table-header-select">
                                <OrderLineStatesSelect
                                    className="order-details-table-status-select"
                                    value={statusId}
                                    onChange={this.setLineStatus}
                                    allowClear={true}
                                    placeholder={<Trans>Stav</Trans>}

                                />
                            </div>
                        </div>,
                        class: 'order-details-table-col-status'
                    },
                    {
                        class: 'order-details-table-col-actions',
                        headerCell: <OrderAddProduct orderDetails={orderDetails}/>,
                        rightsFrom: RIGHTS.MARKETING,
                    },
                ]}
            />
        </div>;
    }

}

export default locationHOC(
    withSessionHOC([SESSION_ATTRIBUTES.SETTINGS])(
        withDataHOC([GLOBAL_DATA.FETCH_HANDLER, GLOBAL_DATA.RELOAD_DATA])(OrderDetailsTable)
    )
);

class OrderDetailsTableRowComponent extends React.PureComponent {
    static propTypes = {
        reload: PropTypes.func.isRequired,
        data: OrderDetailLine.isRequired,
        customerAddresses: PropTypes.arrayOf(UserAddress.isRequired).isRequired,
        transportType: TransportType,
        useVAT: PropTypes.bool,
        isSelling: PropTypes.bool,
        isSent: PropTypes.bool,
        isRemote: PropTypes.bool,
        isNotCovered: PropTypes.bool,
        missingQuantity: PropTypes.number,
        isFinished: PropTypes.bool,
        isDivisible: PropTypes.bool,
        finalOrder: OrderDetail.isRequired,
        onAvailableAtChange: PropTypes.func.isRequired,
        onRequestedAtChange: PropTypes.func.isRequired,
        onCustomerNoticeChange: PropTypes.func.isRequired,
        onDealerNoticeChange: PropTypes.func.isRequired,
        onPriceChange: PropTypes.func.isRequired,
        onQuantityChange: PropTypes.func.isRequired,
        onSequenceChange: PropTypes.func.isRequired,
        onPackageTypeChange: PropTypes.func.isRequired,
        onPriceLevelChange: PropTypes.func.isRequired,
        onStockChange: PropTypes.func.isRequired,
        onAtomicChange: PropTypes.func.isRequired,
        onAddressChange: PropTypes.func.isRequired,
    };

    render() {
        const {
            data, isSelling, isSent, isRemote, finalOrder, reload, onAvailableAtChange, onRequestedAtChange,
            onCustomerNoticeChange, onDealerNoticeChange, onPriceChange, onQuantityChange, isNotCovered,
            missingQuantity, isFinished, onSequenceChange, onPackageTypeChange, onPriceLevelChange, onStockChange,
            isDivisible, onAtomicChange, onAddressChange, transportType, customerAddresses, useVAT,
            [SESSION_ATTRIBUTES.RIGHTS]: rights, [SESSION_ATTRIBUTES.SETTINGS]: settings
        } = this.props;

        const finalCustomer = finalOrder != null ? finalOrder.customer : {};

        return <tr className={getBackgroundStyle(null, {isNotCovered: isNotCovered, isAutoSupply: data.auto_supply, isService: data.is_service})}>
            <td className="text-center">
                { rights >= RIGHTS.MARKETING && (!isFinished || settings.print_order_line_sequence) && !isRemote ?
                    <InputNumber
                        className="text-right"
                        size="small"
                        value={data.sequence}
                        onChange={onSequenceChange}
                    /> :
                    data.sequence
                }
            </td>
            <td>
                <ProductEditableWithMovesRQO
                    productDesignation={data.designation}
                    productManufacturer={data.manufacturer}
                    orderCode={data.order_code}
                    productId={data.id_product}
                    customerId={finalCustomer.id}
                    quotationsCount={data.quotations_count}
                    replacementsCount={data.replacements_count}
                />
                <SpecialTransportIcon flag={data.special_transport}/>
            </td>
            <td>
                <CustomerCodeEditableRQO
                    productCustomerCode={data.customer_code}
                />
            </td>
            <td className="text-right">
                <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                    {formatQuantity(data.package_quantity, data.package_type)}
                    <div className="table-subdata">
                        { data.id_package_type != null ?
                            formatQuantityPerPackage(data.quantity_per_package, data.quantity_units, data.package_type)
                            : null
                        }
                    </div>
                </RightsWrapper>
                <RightsWrapper from={RIGHTS.MARKETING}>
                    <OrderItemQuantityForDealer
                        reload={reload}
                        data={data}
                        isSelling={isSelling}
                        isSent={isSent}
                        missingQuantity={missingQuantity}
                        finalOrder={finalOrder}
                        onQuantityChange={onQuantityChange}
                        onPackageTypeChange={onPackageTypeChange}
                    />
                </RightsWrapper>
            </td>
            <RightsWrapper to={RIGHTS.MARKETING}>
                <td className="text-right">
                    {formatUnitPrice(data.package_price, data.package_type, data.id_currency)}
                    <div className="table-subdata">
                        { data.id_package_type != null ?
                            formatUnitPrice(data.unit_price, data.quantity_units, data.id_currency)
                            : null
                        }
                    </div>
                </td>
            </RightsWrapper>
            <RightsWrapper from={RIGHTS.DEALER}>
                <td className="text-right">
                    { isFinished || isRemote ? 
                        formatUnitPrice(data.package_price, data.package_type, data.id_currency) :
                        <InputUnitPrice
                            size="small"
                            value={data.package_price}
                            onChange={onPriceChange}
                            currency={data.id_currency}
                            min={0}
                            hasError={data.package_price == null}
                        />
                    }
                    { data.quantity_per_package != null && data.unit_price != null ? 
                        <div className="table-subdata">
                            {"("}
                            <UnitPrice 
                                price={data.unit_price} 
                                units={data.quantity_units} 
                                currency={data.id_currency}
                            />
                            {")"}
                        </div> 
                        : null
                    }
                    <OrderLinePriceLevel
                        orderDetailsLine={data}
                    />
                    <PurchasePricesModal
                        size="small"
                        className="full-size-width mt-1"
                        productId={data.id_product}
                        productDesignation={data.designation}
                        productManufacturer={data.manufacturer}
                        onPriceSelected={isSelling ? undefined : onPriceLevelChange}
                    />
                </td>
            </RightsWrapper>
            <td className="text-right">
                {formatPrice(data.line_price, data.id_currency)}
            </td>
            { useVAT ? 
                <td className="text-right">
                    <div>{formatPrice(data.total_price, data.id_currency)}</div>
                    <div>{formatPercentage(data.tax_rate)}</div>
                </td> 
                : null
            }
            <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                <td className="text-right">
                    <OrderAvailableAt
                        orderDetailsLine={data}
                        onChange={onAvailableAtChange}
                    />
                    { data.delivery_address != null ?
                        <div className="table-subdata">
                            <Trans>Dodacia adresa:</Trans>
                            <Address address={data.delivery_address}/>
                        </div>
                        : null
                    }
                </td>
                <td>
                    <pre>{data.customer_notice}</pre>
                </td>
            </RightsWrapper>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <td>
                    <OrderRequestedAt
                        orderDetailsLine={data}  
                        onChange={onRequestedAtChange}  
                    />
                    { isSelling ?
                        <StockOptionsSelect
                            value={data.id_stock}
                            onChange={onStockChange}
                            productId={data.id_product}
                            customerId={finalCustomer.id}
                            quantityUnits={data.quantity_units}
                            label={data.stock != null ? data.stock + (data.stock_description != null ? ' - ' + data.stock_description : '') : null }
                        />
                        : null
                    }
                    <Checkbox
                        disabled={!isDivisible}
                        checked={data.is_atomic == 1}
                        onChange={(e) => {
                            const selected = getInputOnChangeEventChecked(e);
                            onAtomicChange(selected ? 1 : 0);
                        }}
                    >
                        <Trans>iba v celku</Trans>
                    </Checkbox>
                    { data.id_remote_order != null ?
                        ( isRemote ?
                            <div className="pt-3 table-subdata">
                                <Trans>Orig. obj.:</Trans>
                                <Link
                                    className="pl-2 text-dark"
                                    to={ROUTES.ORDER_DETAILS}
                                    queryParams={{[QUERY_PARAMS.ID_ORDER]: data.id_order}}
                                    title={<Trans>Detail pôvodnej objednávky</Trans>}
                                >
                                    {data.order_reference}
                                </Link>
                            </div>
                            :
                            <div className="pt-3 table-subdata">
                                <Trans>Proxy obj.:</Trans>
                                <Link
                                    className="pl-2 text-dark"
                                    to={ROUTES.ORDER_DETAILS}
                                    queryParams={{[QUERY_PARAMS.ID_ORDER]: data.id_remote_order}}
                                    title={<Trans>Detail proxy objednávky</Trans>}
                                >
                                    {data.remote_order_reference}
                                </Link>
                            </div>
                        )
                        : null
                    }
                </td>
                <td className="text-right">
                    <OrderAvailableAt 
                        orderDetailsLine={data}
                        onChange={onAvailableAtChange}
                    />
                    <DeliveryAddressModal
                        currentOption={data.delivery_address}
                        onChange={onAddressChange}
                        isSelling={isSelling}
                        transportType={transportType}
                        customerAddresses={customerAddresses}
                        allowClear={true}
                        placeholder={<Trans>podľa hlavičky objednávky</Trans>}
                    />
                </td>
                <td>
                    <div><Trans>Verejná (viditeľné pre zákazníka):</Trans></div>
                    <InputTextArea
                        size="small"
                        value={data.customer_notice}
                        onChange={onCustomerNoticeChange}
                    />
                    <div className="mt-2"><Trans>Interná (vidí iba obchodník):</Trans></div>
                    <InputTextArea
                        size="small"
                        value={data.dealer_notice}
                        onChange={onDealerNoticeChange}
                    />
                </td>
            </RightsWrapper>
            <td className="overflow-wrap-break-word">{data.status}</td>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <td>
                    <RemoveOrderItem
                        onFinish={reload}
                        orderDetailsLine={data}
                        isRemote={isRemote}
                    />
                </td>
            </RightsWrapper>
        </tr>;
    }
}

const OrderDetailsTableRow = orderItemHOC(
    withSessionHOC([
        SESSION_ATTRIBUTES.RIGHTS,
        SESSION_ATTRIBUTES.SETTINGS,
    ])(OrderDetailsTableRowComponent)
);

class OrderDetailsTileComponent extends React.PureComponent {
    static propTypes = {
        reload: PropTypes.func.isRequired,
        data: OrderDetailLine.isRequired,
        isSelling: PropTypes.bool,
        isSent: PropTypes.bool,
        isNotCovered: PropTypes.bool,
        isFinished: PropTypes.bool,
        isRemote: PropTypes.bool,
        useVAT: PropTypes.bool,
        finalOrder: OrderDetail.isRequired,
        onAvailableAtChange: PropTypes.func.isRequired,
        onRequestedAtChange: PropTypes.func.isRequired,
        onCustomerNoticeChange: PropTypes.func.isRequired,
        onDealerNoticeChange: PropTypes.func.isRequired,
        onPriceChange: PropTypes.func.isRequired,
        onQuantityChange: PropTypes.func.isRequired,
        onPackageTypeChange: PropTypes.func.isRequired,
        onPriceLevelChange: PropTypes.func.isRequired,
    };

    render() {
        const {
            data, isSent, isRemote, finalOrder, reload, onAvailableAtChange, onRequestedAtChange,
            onCustomerNoticeChange, onDealerNoticeChange, onPriceChange, onQuantityChange, isNotCovered,
            isFinished, useVAT
        } = this.props;
        const finalCustomer = finalOrder != null ? finalOrder.customer : {};
        return <Tile className={'p-2' + getBackgroundStyle(null, {isNotCovered: isNotCovered, isAutoSupply: data.auto_supply, isService: data.is_service})}>
            <div className="flex-row-dynamic-static">
                <div className="align-self-center">
                    <Link
                        className="text-dark"
                        to={ROUTES.PRODUCT_DETAILS}
                        queryParams={{[QUERY_PARAMS.ID_PRODUCT]: data.id_product}}
                    >
                        <h4 className="px-2 mt-1">{data.designation + ' (' + data.manufacturer + ')'}</h4>
                    </Link>
                    <RightsWrapper from={RIGHTS.WHOLESALE_CUSTOMER}>
                        <TileAttribute
                            title={<Trans>Vlastné ozn.</Trans>}
                            value={
                                <CustomerCodeEditableRQO
                                    productCustomerCode={data.customer_code}
                                />
                            }
                        />
                    </RightsWrapper>
                </div>
                <RightsWrapper from={RIGHTS.MARKETING}>
                    <RemoveOrderItem
                        buttonClassName="m-1"
                        onFinish={reload}
                        orderDetailsLine={data}
                        isRemote={isRemote}
                    />
                </RightsWrapper>
            </div>
            <div className="d-flex">
                <TileAttribute
                    title={<Trans>Množstvo</Trans>}
                    value={
                        <React.Fragment>
                            <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                                <span className="text-nowrap">{formatQuantity(data.package_quantity, data.package_type)}</span>
                            </RightsWrapper>
                            <RightsWrapper from={RIGHTS.MARKETING}>
                                {
                                    isSent && isRemote ?
                                    <span className="text-nowrap">{formatQuantity(data.package_quantity, data.package_type)}</span> 
                                    :
                                    <InputQuantity
                                        className="text-right"
                                        size="small"
                                        value={numberOrNull(data.package_quantity)}
                                        onChange={onQuantityChange}
                                        unit={data.package_type}
                                        min={0}
                                    />
                                }
                            </RightsWrapper>
                        </React.Fragment>
                    }
                />
                <TileAttribute
                    title={<Trans>Jedn. cena</Trans>}
                    value={
                        <React.Fragment>
                            <RightsWrapper to={RIGHTS.MARKETING}>
                                {formatUnitPrice(data.package_price, data.package_type, data.id_currency)}
                            </RightsWrapper>
                            <RightsWrapper from={RIGHTS.DEALER}>
                                { isFinished || isRemote ?
                                    formatUnitPrice(data.package_price, data.package_type, data.id_currency) :
                                    <InputUnitPrice
                                        size="small"
                                        value={data.package_price}
                                        onChange={onPriceChange}
                                        unit={data.package_type}
                                        currency={data.id_currency}
                                        min={0}
                                        hasError={data.package_price == null}
                                    />
                                }
                            </RightsWrapper>
                        </React.Fragment>
                    }
                />
            </div>
            <TileAttribute
                title={<Trans>Spolu</Trans>}
                value={
                    <TaxedPriceDisplay
                        linePrice={data.line_price}
                        totalPrice={data.total_price}
                    />
                }
            />
            { useVAT ? 
                <TileAttribute
                    title={<Trans>DPH</Trans>}
                    value={formatPercentage(data.tax_rate)}
                />
                : null
            }
            <RightsWrapper from={RIGHTS.MARKETING}>
                <TileAttribute
                    title={<Trans>Dátum potreby</Trans>}
                    value={
                        <OrderRequestedAt
                            orderDetailsLine={data}
                            onChange={onRequestedAtChange}
                        />
                    }
                />
            </RightsWrapper>
            <TileAttribute
                title={<Trans>Dodací termín</Trans>}
                value={
                    <OrderAvailableAt 
                        className="half-width"
                        orderDetailsLine={data}
                        onChange={onAvailableAtChange}
                    />
                }
            />
            <TileAttribute
                className="full-size-width flex-wrap"
                title={<Trans>Poznámka</Trans>}
                value={
                    <React.Fragment>
                        <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                            <pre>{data.customer_notice}</pre>
                        </RightsWrapper>
                        <RightsWrapper from={RIGHTS.MARKETING}>
                            <InputTextArea
                                size="small"
                                value={data.customer_notice}
                                onChange={onCustomerNoticeChange}
                            />
                        </RightsWrapper>
                    </React.Fragment>
                }
            />
            <RightsWrapper from={RIGHTS.MARKETING}>
                <TileAttribute
                    className="full-size-width flex-wrap"
                    title={<Trans>Interná poznámka</Trans>}
                    value={
                        <InputTextArea
                            size="small"
                            value={data.dealer_notice}
                            onChange={onDealerNoticeChange}
                        />
                    }
                />
            </RightsWrapper>
            <TileAttribute
                title={<Trans>Stav</Trans>}
                value={data.status}
            />
            <RightsWrapper from={RIGHTS.MARKETING}>
                <TileAttribute
                    title={<Trans>Pohyby</Trans>}
                    value={
                        <ProductMoves
                            productDesignation={data.designation}
                            productManufacturer={data.manufacturer}
                            productId={data.id_product}
                            customerId={finalCustomer.id}
                            quotationsCount={data.quotations_count}
                        />
                    }
                />
            </RightsWrapper>
            <SpecialTransportIcon flag={data.special_transport} className="px-2"/>
        </Tile>
    }
};

const OrderDetailsTile = orderItemHOC(OrderDetailsTileComponent);